import { css, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight1: '#FFFFFF',
      neutralLight2: '#FDFCF7',
      neutralLight3: '#E1EAE9',
      neutralDark1: '#000000',
      neutralDark2: '#464E53',
      primaryDark: '#132E39',
      secondaryDark: '#24596F',
      primaryLight: '#77AAB2',
      secondaryLight: '#E4C792',
      danger: '#CC5858',
    },
  },
  fontFamily: {
    heading: "'Fraunces', serif",
    paragraph: "'Poppins', sans-serif",
  },
}

export type Styles = typeof styles

export const styles = {
  h1: css`
    margin: 2.5vw 0;
    font-size: 6.5vw;
    font-weight: 400;
    line-height: 1;
    white-space: nowrap;
    font-family: ${theme.fontFamily.heading};
    color: ${theme.colors.variants.neutralLight1};

    @media (max-width: 991px) {
      font-size: 60px;
    }
  `,
  h2: css`
    font-size: 3.5vw;
    font-weight: 400;
    line-height: 1;
    font-family: ${theme.fontFamily.heading};
    color: ${theme.colors.variants.primaryDark};

    strong {
      color: ${theme.colors.variants.primaryLight};
    }

    @media (max-width: 991px) {
      font-size: 35px;
    }
  `,
  h3: css`
    line-height: 1;
    font-size: 2.1vw;
    font-weight: 400;
    margin-bottom: 15px;
    font-family: ${theme.fontFamily.heading};
    color: ${theme.colors.variants.primaryDark};

    @media (max-width: 1399px) {
      font-size: 3vw;
    }

    @media (max-width: 991px) {
      font-size: 25px;
      margin-bottom: 10px;
    }
  `,

  label: css`
    letter-spacing: 1px;
    font-size: 1.87vw;
    font-weight: 300;
    font-family: ${theme.fontFamily.heading};
    color: ${theme.colors.variants.primaryDark};

    @media (max-width: 991px) {
      font-size: 25px;
      margin-bottom: 10px;
    }
  `,
  description: css`
    font-size: 15px;
    font-weight: 200;
    line-height: 1.8;
    font-family: ${theme.fontFamily.paragraph};
    color: ${theme.colors.variants.primaryDark};

    @media (max-width: 1599px) {
      font-size: 16px;
    }
    @media (min-width: 2000px) {
      font-size: 21px;
    }
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 1.5;
    }
  `,
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider disableDynamicMediaQueries>
        {children}
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
